$main: #373778;
$text-color: #2C2D2E;
$text-color-secondary: #919399;
$gray-20: #F9F9FA;
$gray-40: #F3F3F5;
$font: 'Golos', sans-srif;
$border-color: rgba(0, 16, 61, 0.12);
$border-color-active: rgba(0, 16, 61, 0.24);

@mixin font ($font, $weight, $size, $height, $color) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}

/*XL*/

@media screen and (max-width: 2560px) {
  .modal-dialog{
    max-width: 782px;
    padding: 0 16px;
  }
}


/*LG*/

@media screen and (max-width: 1920px) {

}

/*MD*/

@media screen and (max-width: 1680px) {

  .main_screen_title{
    margin-top: -124px;
  }

  .container_center {
    padding: 0 64px;
  }
  .stamp_container{
    padding: 0 64px;
  }
.text_wrap{
  padding-top: calc(92px + (192 - 92) * ((100vw - 360px) / (1680 - 360)));
  padding-right: calc(16px + (112 - 16) * ((100vw - 360px) / (1680 - 360)));
  padding-left: calc(16px + (112 - 16) * ((100vw - 360px) / (1680 - 360)));
  padding-bottom: calc(128px + (380 - 128) * ((100vw - 360px) / (1680 - 360)));
}
  .main-screen__canvas{
    width: calc(700px + (920 - 700) * ((100vw - 360px) / (1680 - 360)));
  }
  .stamp_img{
    width: calc(356px + (640 - 356) * ((100vw - 360px) / (1680 - 360)));
    height: calc(356px + (640 - 356) * ((100vw - 360px) / (1680 - 360)));
  }
  .logo_text-bold{
    font-size: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
  }
  .subtitle{
    font-size: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (40 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .logo_text{
    font-size: calc(16px + (20 - 16) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
  }

  .main-screen__title {
    font-size: calc(34px + (96 - 34) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(44px + (112 - 44) * ((100vw - 360px) / (1680 - 360)));
  }
  .main-screen__title b {
    font-size: calc(34px + (96 - 34) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(44px + (112 - 44) * ((100vw - 360px) / (1680 - 360)));
  }
  h2{
    font-size: calc(32px + (48 - 32) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(40px + (64 - 40) * ((100vw - 360px) / (1680 - 360)));
  }
  .main_subtitle{
    font-size: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (40 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .main_text{
    font-size: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (48 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .white_title{
    font-size: calc(32px + (48 - 32) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(40px + (64 - 40) * ((100vw - 360px) / (1680 - 360)));
  }
  .main_text_white{
    font-size: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (48 - 32) * ((100vw - 360px) / (1680 - 360)));
  }

  .main_subtitle{
    font-size: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (40 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .oblast_block{
    margin-bottom: calc(32px + (66 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .agreement{
    margin-top: calc(32px + (64 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  h3{
    font-size: calc(24px + (48 - 24) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(32px + (64 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .author_name{
    font-size: calc(16px + (20 - 16) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
  }
  .author_img{
    width: calc(80px + (96 - 80) * ((100vw - 360px) / (1680 - 360)));
    height: calc(80px + (96 - 80) * ((100vw - 360px) / (1680 - 360)));
  }
  .author_img_single{
    width: calc(64px + (96 - 64) * ((100vw - 360px) / (1680 - 360)));
    height: calc(64px + (96 - 64) * ((100vw - 360px) / (1680 - 360)));
  }
  .passport_screen{
    margin-top: calc(-120px + (-340 + 120) * ((100vw - 360px) / (1680 - 360)));
  }
  .middle_block{
    margin-top: calc(-92px + (-160 + 92) * ((100vw - 360px) / (1680 - 360)));
  }
  p{
    font-size: calc(16px + (20 - 16) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
  }
  .subtitle_crowd{
    font-size: calc(16px + (20 - 16) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
    margin-bottom: calc(32px + (56 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .reward_subtitle{
    font-size: calc(14px + (16 - 14) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(24px + (32 - 24) * ((100vw - 360px) / (1680 - 360)));
  }
  .document{
    margin-bottom: calc(32px + (56 - 32) * ((100vw - 360px) / (1680 - 360)));
  }
  .author_position,
  .author_region{
    font-size: calc(14px + (16 - 14) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(20px + (24 - 20) * ((100vw - 360px) / (1680 - 360)));
  }
  .footer_text{
    font-size: calc(12px + (16 - 12) * ((100vw - 360px) / (1680 - 360)));
    line-height: calc(16px + (24 - 16) * ((100vw - 360px) / (1680 - 360)));
  }

  .insta-gradient__a {
    display: none;
  }
  .insta-block__info {
    height: 60px;
    opacity: 1;
  }
  .contact-leader {
    grid-auto-columns: 100%;
    grid-auto-rows: 304px 1fr;
  }
  .contact-leader__img {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    object-position: top;
  }
  .contact-leader__content {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .preview-event-card__title_block,
  .preview-event-card__title_list {
    @include font ($font, 500, 18px, 26px, $text-color);
    margin-bottom: 8px;
  }
  .preview-event-card__date_list,
  .preview-event-card__date_block {

  }
}


/* ~~SM - MD~~ */
@media screen and (max-width: 1440px) {
  .first-screen{
    padding: 0 84px;
  }
  .pasport_video {
    max-width: 820px;
  }
  #grid{
    grid-gap: 12px 48px;
  }
  .main_screen_title{
    margin-top: -64px;
  }


  .block-items {
    flex-wrap: nowrap;
    overflow: auto;
    margin-bottom: 32px;
  }
  .insta::-webkit-scrollbar,
  .youtube::-webkit-scrollbar,
  .block-items::-webkit-scrollbar {
    height: 8px;
  }
  .insta::-webkit-scrollbar-track,
  .youtube::-webkit-scrollbar-track,
  .block-items::-webkit-scrollbar-track {
    background-color: $gray-40;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 0 24px;
  }
  .insta::-webkit-scrollbar-thumb,
  .youtube::-webkit-scrollbar-thumb,
  .block-items::-webkit-scrollbar-thumb {
    background-color: $text-color-secondary;
    border-radius: 2px;
  }


  .block-items .block-item {
    flex: none;
    width: 40vw;
  }
  .block-item {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1280px) {
  .first-screen{
    padding: 0 64px;
  }


  .author_frame{
    margin-right: 16px;
  }
  .main_screen_title{
    margin-top: 0px;
  }

  .download_button
  {
    background: #266FFF;
    position: relative;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    display: block;
    text-decoration: none;
    box-shadow: 0px 0px 40px #266FFF;
    box-shadow: 0px 0px 40px #266FFF;

    &:hover{
      animation-name: shadow_puls;
      animation-iteration-count:infinite;
      animation-duration: 2s;
      .border_blue{
        width: 220px;
        height: 220px;
      }
      .border_gray{
        width: 260px;
        height: 260px;
      }
    }
  }



  .border_gray,
  .border_blue{
    width: 123px;
    height: 123px;
  }
  .download_arrow{
    width: 20%;
  }
  .gerb_frame{
    width: 50%;
  }

  #grid_table{

    grid-template-rows: repeat(8, auto);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: calc(24px + (48 - 24) * ((100vw - 360px) / (1280 - 360)));
    grid-column-gap: calc(32px + (128 - 32) * ((100vw - 360px) / (1280 - 360))) ;

  }

  .container_center-content {
    padding: 0 64px;
  }


  .img_1_1{
    width: 96px;
    max-width: 100%;
    height: 120px;
  }
  .img_1_15{
    width: 72px;
    max-width: 100%;
    height: 90px;
  }
  .img_1_2{
    width: 48px;
    max-width: 100%;
    height: 60px;
  }
  .img_1_4{
    width: 24px;
    max-width: 100%;
    height: 30px;
  }
  .img_1_8{
    width: 12px;
    max-width: 100%;
    height: 15px;
  }


}


/*SM*/


@media screen and (max-width: 1024px) {
  .stamp_container{
    padding: 0 24px;
  }
  .first-screen {
    height: 200vh;
  }
  .main-screen__sequences{
    top: 35%;
  }
  .first-screen_content{
    padding: 0 0 96px;
  }
  .main-screen{
    align-items: flex-end;
    justify-content: center;
  }
  .main-screen__title{
    text-align: center;
  }
  .first-screen{
    padding: 0 32px;
  }

  .main_screen-textblock{
    padding-bottom: 96px;
  }
  .main_screen {
    justify-content: end;
    align-items: center;
  }
  .logo_wrap{
    display: flex;
    justify-content: center;
  }
  .back_img{
    object-fit: cover;
  }
  .accordion-body{
    padding: 12px 16px 4px 16px;
  }
  .accordion-button{
    padding: 12px 16px;
  }
  .uniform_svg{
    align-self: center;
    margin: 56px 0 24px 0;
  }
  .shop-btn_wrap{
    padding-bottom: 48px;
  }
  .shop-title{
    padding-bottom:24px;
  }
  .souvenir_price_detail{
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .modal-content {
    padding: 16px;
  }
  .modal_form_content{
    padding: 0;
  }
  .download_button{
    margin-bottom: 72px;
  }

  .author_plugin{
    float: unset;
  }
  .doc_download_text{
    line-height: 24px;
  }
  .grid_padding{
    padding-bottom: 32px;
  }
  .author_text {
    padding: 12px 16px 24px 16px;
  }
  .project_offer{
    padding-bottom: 0;
  }

  .oblast_title,
  .offer_title
  {
    font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1025 - 320)));
    line-height: calc(32px + (48 - 32) * ((100vw - 320px) / (1025 - 320)));
  }
  .offer_text{

    margin-top: 0px;
  }

  #example_grid {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);

  }
  .purpose_gerb{
    padding: 32px 0 48px 0;
  }


  .download_button{
    background: #266FFF;
    position: relative;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    display: block;
    text-decoration: none;
    box-shadow: 0px 0px 40px #266FFF;
    margin-top: 80px;


    &:hover{
      animation-name: none;
    }
  }
  .download_button_two{
    background: #266FFF;
    position: relative;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    display: block;
    text-decoration: none;
    box-shadow: 0px 0px 96px #266FFF;
    margin-top: 24px;

    &:hover{
      animation: none;
    }
  }

  .border_gray,
  .border_blue{
    position: absolute;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: none;
  }
  .border_gray{
    width: 260px;
    height: 260px;
  }
  .border_blue{
    width: 220px;
    height: 220px;
  }

  .uniform{
    margin-bottom: 48px;
  }
  .download_block{
    padding-top: 48px;
  }
  .example_block{
    padding-top: 48px;
  }
  .concept{
    padding: 32px 0 40px 0;
  }
  #grid_table{

    grid-template-rows: repeat(10, auto);
    grid-template-columns: repeat(2, 1fr);

  }


  .concept-subtitle{
    padding-bottom: 24px;
  }

  #grid {
    padding-right: 0;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto auto;
    align-items: center;
    grid-gap: 48px 24px;
  }
  .img_1_1{
    width: 128px;
    max-width: 100%;
    height: 160px;
  }
  .img_1_15{
    width: 96px;
    max-width: 100%;
    height: 120px;
  }
  .img_1_2{
    width: 64px;
    max-width: 100%;
    height: 80px;
  }
  .img_1_4{
    width: 32px;
    max-width: 100%;
    height: 40px;
  }
  .img_1_8{
    width: 16px;
    max-width: 100%;
    height: 20px;
  }

  .scalability{
    padding: 48px 0;
  }

  .gerb-grid__p{
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .gerb-grid__img{
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .gerb-grid__svg{
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .gerb-grid__p:nth-child(1),
  .gerb-grid__img.img_1_1,
  .gerb-grid__svg.img_1_1 {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .gerb-grid__p:nth-child(2),
  .gerb-grid__img.img_1_15,
  .gerb-grid__svg.img_1_15 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .gerb-grid__p:nth-child(3),
  .gerb-grid__img.img_1_2,
  .gerb-grid__svg.img_1_2 {
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .gerb-grid__p:nth-child(4),
  .gerb-grid__img.img_1_4,
  .gerb-grid__svg.img_1_4 {
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .gerb-grid__p:nth-child(5),
  .gerb-grid__img.img_1_8,
  .gerb-grid__svg.img_1_8 {
    grid-row-start: 5;
    grid-row-end: 6;
    align-self: center;
  }

  .concept-text{
    padding-left: 16px;
  }


  .container_center {
    padding: 0 16px;
  }

  .main_screen-title,
  .main_screen-subtitle,
  .main_screen-btn{
    text-align: center;
  }
  .main_screen-imgblock{
    padding: 26px 0 32px 0;
    align-items: center;
    height: fit-content;
    height: 60%;
    margin-top: unset;
  }
  .main-btn{
    width: 100%;
  }

  .block-items .block-item {
    width: 70vw;
  }
  .insta {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .insta__item {
    min-width: 40vw;
  }
  .date-range_wrap {
    margin: 8px 0;
    a.date-select__close {
      float: right;
    }
  }
  .category-page__filter-toggle {
    float: right;
  }
  .date-select {
    flex-direction: row-reverse;
  }
  .category-page__filter {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;

  }
  .date-select {
    font-size: 0;
  }
  .banner {
    padding: 20px;
  }
  .items-plugin .block-item {
    padding-right: 8px;
    padding-left: 8px;
  }
  .insta__item {
    padding-right: 16px;
  }
  .preview-event-card__img-container_block {
    &._hide-anons {
      display: block;
    }
  }
  .preview-event-card__title_list {
    margin-top: 16px;
  }
  .preview-event-card__body_block {
    flex-direction: column-reverse;
    margin-top: 16px;
  }
  .preview-event-card_block {
    padding-bottom: 24px;
  }
  .preview-event-card_list {
    margin-bottom: 24px;
  }
  .preview-event-card_block,
  .preview-event-card_list {

  }
  .news-item:last-child .preview-event-card_list {
    border-bottom: none
  }
  .event-card_list {
    padding: 12px 0;
    border-bottom: none;
  }
  .event-card__title_list {
    margin-top: 16px;
    font-size: 18px;
    line-height: 26px;
  }
}


@media screen and (max-width: 800px) {
  .first-screen{
    padding: 0 16px;
  }

  .middle_block{
    padding: 0 16px;
  }
  .project_status_big,
  .gerb_number_big{
    font-size: 16px;
  }
  .solid_gerb_big{
    width: 24px;
    height: 30px;
  }
  .main-btn-blue{
    font-size: 16px;
    line-height: 24px;
  }
  #example_grid {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(5, 1fr);
    align-items: start;
    justify-items: center;
    grid-gap: 24px 24px;
    width: 100%;
    margin-top: 48px;
    overflow-x: auto;
  }
  .example_table::-webkit-scrollbar{
    width: 0 !important;
  }
  .example_img {
    width: 60vw;
    height: 60vw;
    max-width: 400px;
    max-height: 400px;
  }
  .example_text{
    position: sticky;
    left: 0;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (880 - 320)));
    grid-column-start: 1;
    grid-column-end: 3;
    max-width: calc(100vw - 32px);
    justify-self: start;
  }

  .container_center-content{
    padding: 0 32px;
  }
  .concept_img {
    width: 100%;
    height: 100%;
    max-width: 64px;
    max-height: 64px;
  }

}




/*XS*/

@media screen and (max-width: 600px) {

  .scroll-downs {
   display: none;
  }

  .footer_text{
    max-width: 199px;
  }
  .form-control{
    max-width: none;
  }
  .select_frame_wizard{
    max-width: none;
  }
  .row{
    margin-right: -16px;
    margin-left: -16px;
  }
  .row > *{
    padding-right: 16px;
    padding-left: 16px;
  }

  .uniform_gerbs{
    margin: 40px 0 16px 0 ;
  }

  #grid {
    padding-top: 40px;
  }
  .scaleblock_padding{
    padding: 28px 0 52px 0;
  }
  .text_rect_big{
    margin: 0 -16px;
    padding: 16px;
  }
  .text_rect{
    margin: 0 -16px;
    padding: 16px;
  }
  .container-fluid {
    padding-right: 24px;
    padding-left: 24px;
  }
  .u-links {
    //grid-template-columns: 1fr;
  }
  .insta__item {
    min-width: 70vw;
  }
  .up-button__button {
    right: 16px;
  }
  .paginate-item {
    font-size: 12px;
    line-height: 16px;
    height: 28px;
    min-width: 28px;
    padding: 2px 5px;
    margin: 0 2px;
  }
  .container_center-content{
    padding: 0 16px;
  }
  .img_1_1{
    width: 96px;
    max-width: 100%;
    height: 120px;
  }
  .img_1_15{
    width: 72px;
    max-width: 100%;
    height: 90px;
  }
  .img_1_2{
    width: 48px;
    max-width: 100%;
    height: 60px;
  }
  .img_1_4{
    width: 24px;
    max-width: 100%;
    height: 30px;
  }
  .img_1_8{
    width: 12px;
    max-width: 100%;
    height: 15px;
  }
}



