//$main: #0080FF;
$main: #0181ff;
$main_dark: #0A37BF;
$text-color: #2C2D2E;
$text-color-secondary: #919399;
$gray-20: #F9F9FA;
$gray-40: #F3F3F5;
$white : #FFFFFF;
$font: 'Golos', sans-srif;
$border-color: rgba(0, 16, 61, 0.12);
$border-color-active: rgba(0, 16, 61, 0.24);


@mixin size ($height, $width) {
  height: $height;
  width: $width;
}
@mixin font ($font, $weight, $size, $height, $color) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}



body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.accessability {
  transform: translateY(-1px);
}
.main-content {
  flex: 1 0 auto;
}

a,
a:hover {
  text-decoration: none;
  outline: none;
}
h2 {
  @include font($font, 500, 48px, 64px, $main);
  margin-top: 64px;
  margin-bottom: 12px;
}
h3{
  @include font($font, 600, 48px, 64px, $text-color);
}
p{
  @include font($font, 400, 20px, 32px, $text-color);
  margin: 0;
}

hr {
  margin: 0 !important;
  color: $border-color;
  background-color: $border-color;
  opacity: 1;
}

blockquote {
  display: block;
  padding: 48px;
  background: $gray-20;
}

.block-item {
  margin-bottom: 48px;
}
.link-title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 20px;
}
.link-title:hover {
  color: $main;
}

.r-8 {
  border-radius: 8px;
  overflow: hidden;
}
.header {
  background-color: $main;
  height: fit-content;
  width: 100%;
  padding: 32px 0;
}


.light {
  .header {
    background-color: $white;
    height: fit-content;
    width: 100%;
    padding: 32px 0;
  }
}
.light{
  .header_text{
    color: $main;
  }
  .burger__span{
    color: $main;
    border-color: $main;
  }
  .footer_img use{
    fill: $main;
  }
}


.light {
  .nav__link_mobile  {
    @include font ($font, 400, 16px, 32px, $text-color);
    padding-right: 32px;
    &:hover{
      color: $text-color;
      opacity: 0.8;
    }
  }
}
.header_mobile.light{
  display: block;
  top: 0;
  width: 100%;
  z-index: 40;
  background-color: $white;
  //box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
}

.breadcrumb {
  background-color: transparent;
  padding: 24px 0 16px;
  margin-bottom: 0;
  flex-wrap: nowrap;
}
.breadcrumb-item {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #919399;

  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: '·';
}
.site__title {
  display: flex;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $text-color;
  padding: 20px 0 24px;
}
.site__logo {
  display: block;
  height: 52px;
  width: auto;
  margin-right: 16px;
}


.top-muted-link {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  color: $text-color-secondary;
  margin-right: 24px;
}
.social-icon {
  display: block;
  height: 20px;
  width: 20px;
  margin-right: 32px;
  background-size: contain;
}
.icon-vk {
  background: url("../img/socials/vk.svg") center no-repeat;
}
.icon-facebook{
  background: url("../img/socials/facebook.svg") center no-repeat;
}
.icon-twitter {
  background: url("../img/socials/twitter.svg") center no-repeat;
}
.icon-instagram {
  background: url("../img/socials/instagram.svg") center no-repeat;
}
.icon-odnoklassniki {
  background: url("../img/socials/odnoklassniki.svg") center no-repeat;
}
.search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url("../img/search.svg") center no-repeat;
}
#wcag-collapse-button::after {
  content: url("../img/wsag-icon.svg");
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
  margin-left: 6px;
}

.nav__item {
  display: flex;
  justify-content: space-between;
}
.nav__link {
  display: flex;
  @include font ($font, normal, 14px, 64px, $text-color);
  margin-right: 24px;
  height: 70px;
  flex-grow: 1;
}

.nav__link_secondary {
  display: flex;
  @include font ($font, normal, 12px, 32px, $text-color);
  align-items: center;
  margin-right: 24px;
}
.nav__sub-link_secondary {
  display: flex;
  width: 100%;
  @include font ($font, normal, 12px, 20px, $text-color);
  align-items: center;
  justify-content: space-between;
  padding: 6px 0 6px;
}
.nav > .nav__item .nav__link {
  position: relative;
}
.nav > .nav__item .nav__link::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px;
  left: -12px;
  width: calc(100% + 24px);
  height: 0;
  background-color: transparent;
  transition: .2s;
}
.nav > .nav__item .nav__link.active::after,
.nav > .nav__item .nav__link:hover::after {
  height: 4px;
  background-color: $main;
}
.nav {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0 12px;
  overflow-x: auto;
  overflow-y: hidden;
}
.nav::-webkit-scrollbar {
  display: none;
}
.nav_secondary {
  display: flex;
  padding: 12px 16px;
  background-color:$gray-20 ;
  border-radius: 0 0 8px 8px;
  flex-wrap: wrap;
}
.nav__item_secondary {
  display: flex;
  justify-content: space-between;
  position: relative;
  .nav__link {
    @include font($main, normal, 12px, 20px, $text-color);
  }
}
.nav__sub-item_secondary {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 16px 0 32px;
  background-color: #fff;
  transition: 0.1s;
}
.nav__sub-item_secondary:hover {
  background-color: $gray-40;
  cursor: pointer;
}
.nav__dropdown {
  display: block;
  opacity: 0;
  pointer-events: none;
  top: 100%;
  left: -16px;
  position: absolute;
  width: 320px;
  background-color: #fff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 20;
  padding: 16px 0 16px;
  transition: 0.2s;
}
.nav__item_secondary:hover > .nav__dropdown {
  opacity: 1;
  pointer-events: auto;
}
.nav__sub-dropdown {
  display: block;
  opacity: 0;
  pointer-events: none;
  top: -16px;
  left: 100%;
  position: absolute;
  width: 320px;
  background-color: #fff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 20;
  padding: 16px 0 16px;
  transition: 0.1s;
}
.nav__sub-item_secondary:hover > .nav__sub-dropdown {
  opacity: 1;
  pointer-events: auto;
}
.nav__bg {
  display: flex;
  position: absolute;
  height: calc(100vh - 168px);
  height: 50vh;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid $main;
}
.nav__children-wrap {
  max-height: 100%;
  height: 100%;
  width: 33.33%;
  overflow-y: auto;
  padding: 12px 0;
  border-right: 1px solid $border-color;
}
.nav__children-wrap:nth-child(3) {
  border-right: none;
}
.nav__children-wrap::-webkit-scrollbar {
  width: 4px;
}

.nav__children-wrap::-webkit-scrollbar-track {
  background-color: $gray-20;
  box-shadow: none;
  outline: none;
  border: none;
}

.nav__children-wrap::-webkit-scrollbar-thumb {
  background-color: $text-color-secondary;
  border-radius: 2px;
}


.nav__children-wrap .nav__link {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  height: unset;
  padding: 8px 24px;
  margin-right: 0;
}


.nav__arrow::before {
  content: url("../img/small-arrow.svg");
  transform: rotate(-90deg);
  height: 24px;
  width: 24px;
}

.nav__arrow {
  flex: none;
  flex-grow: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav__children-wrap .nav__link.active,
.nav__children-wrap .nav__link:hover {
  background-color: $gray-20;
}

.nav > .nav__item .nav__link {
  align-items: center;
  margin: 0 12px;
}

.nav__arrow_secondary,
.nav__arrow_main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include size (20px, 20px);
}

.nav__arrow_main::before {
  content: '';
  display: block;
  @include size (0, 0);
  border: 5px solid transparent;
  border-top: 5px solid #BFC1C7;
  transform: translateY(4px);
}

.nav__arrow_secondary::before {
  content: '';
  display: block;
  @include size (0, 0);
  border: 4px solid transparent;
  border-top: 4px solid #BFC1C7;
  transform: translateY(2.25px);
}
.nav__sub-link_secondary .nav__arrow_secondary::before {
  transform: none;
  border: 4px solid transparent;
  border-left: 4px solid #BFC1C7;
}


.search-container {
  pointer-events: none;
  position: absolute;
  height: 70px;
  width: 100%;
  top: 1px;
  left: 0;
  opacity: 0;
  background-color: #fff;
  transition: .2s ease-out;
}


.search-container.active {
  pointer-events: unset;
  opacity: 1;
  left: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

textarea,
input[type="text"],
input[type="select"],
input[type="search"] {
  -webkit-border-style: solid;
  -moz-border-style: solid;
  border-style: solid;
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}


input[type="search"],
input[type="search"]::-webkit-search-cancel-button {
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}




.inner-search {
  display: flex;
  align-items: center;
  position: relative;
}

.inner-search .ac-results {
  position: absolute;
  top: 71px;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 40;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid $main;
}
.inner-search .ac-result {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding: 8px 16px;
  color: #2C2D2E;
  text-decoration: none;
}
.inner-search .ac-result:hover {
  background-color: $gray-20;
  color: #2C2D2E;
}
.ac-results_mobile {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 50;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid $main;
  .ac-result_mobile {
    display: block;
    width: 100%;
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    padding: 8px 16px;
    color: #2C2D2E;
    text-decoration: none;
  }
}

.inner-search .search-input {
  height: 100%;
  border: none;
  flex-grow: 1;
}
.inner-search .search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/search-black.svg) center no-repeat;
  margin-right: 24px;
}
.inner-search .search-btn,
.main-btn {
  display: inline-block;
  padding: 16px 32px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #FFF;
  background-color: #E53948;
  border-radius: 8px;
  text-align: center;
  border: none;
  transition: 0.7s;
}
.site_mobile{
  .main-btn{
    box-shadow: 0px 4px 48px #e664d1;
    transition: 0.7s;
  }
}
.submit_btn,
.btn-primary{
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
  background-color: #266FFF;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-top: 18px;
  transition: 0.3s;
  &:hover{
    color: white;
    box-shadow: 0px 4px 12px rgba(29, 83, 191, 0.25);
  }
}

.file_btn{
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $main;
  background-color: $white;
  border-radius: 4px;
  text-align: center;
  border: 1px solid $main;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  &:hover{
    background: $main;
    color: white;
  }
}
.main-btn-blue {
  display: inline-block;
  padding: 16px 32px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #FFF;
  background-color: $main;
  border-radius: 8px;
  text-align: center;
  border: none;
  width: fit-content;
  white-space: nowrap;
  transition: 0.3s;
  &:hover{
    color: white;
    box-shadow: 0px 4px 12px rgba(29, 83, 191, 0.25);
  }
}


.main_screen-btn{
  margin-top: 32px;

}
.main-btn:hover{
  box-shadow: 0px 4px 48px #E664D1;
  transition: 0.7s;
}


.inner-search .search-btn:hover,
.main-btn:hover {
  color: #fff;
}
.inner-search .search-btn {
  margin: 0 24px;
}
.inner-search .close-btn {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/cross.svg) center no-repeat;
}
.search-input_mobile:focus,
.inner-search .search-input:focus {
  border: none;
  outline: none;
}
.searchInputBox,
.searchInputBox .form-search,
.searchInputBox .inner-search {
  height: 100%;
}


#menu-app {
  min-height: 70px;
}


/* START Mobile header */

.header_mobile {
  display: block;
  top: 0;
  width: 100%;
  z-index: 40;
  background-color: $main;
  //box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
}

.header__info_mobile {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  //height: 48px;
  padding: 16px;
}
.logo_mobile {
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 16px;
  max-width: 400px;
}
.logo__img_mobile {
  display: block;
  @include size(40px, 40px);
  object-fit: contain;
  object-position: center left;
  margin-right: 8px;
}
.logo__text_mobile {
  @include font ($font, normal, 10px, 13px, $text-color);
  margin-bottom: 0;
  .logo__text_bold {
    font-size: 12px;
  }
}
.logo__text_bold {
  font-weight: 700;
}




.burger {
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: none;
}

.site_mobile{
  .burger{
    display: flex;
  }
}
.light{
  .burger{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
  }
}
.burger span {
  position: relative;
}

//.burger span::before,
//.burger span::after {
//  content: '';
//  position: absolute;
//  top: 0;
//  transform-origin: center;
//}
.burger span::before,
.burger span::after,
.burger__span,
.burger__span_close{
  @include font ($font, 500, 16px, 28px, $white);
  display: block;
  transition: 0.2s;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 10px;
}

.burger__span:hover {
  color: white!important;
}
.burger__span:focus {
  color: white!important;
}
.burger__span:active {
  color: white!important;
}
.burger:hover {
  color: white!important;
}
.burger:focus {
  color: white!important;
}
.burger:active {
  color: white!important;
}
.burger__span_close:hover {
  color: white!important;
}
.burger__span_close:focus {
  color: white!important;
}
.burger__span_close:active {
  color: white!important;
}

.header__menu_active {
  height: 100vh;
  overflow: hidden;
  .burger span {
    background-color: transparent;
  }
  .burger span::before {
    transform: rotate(45deg);
  }
  .burger span::after {
    transform: rotate(-45deg);
  }
}
.main-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  @include size(48px, 48px);
  span {
    position: relative;
  }
  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 0;
    transform-origin: center;
    display: block;
    @include size(2px, 20px);
    background-color: #BFC1C7;
    transition: 0.2s;
  }
  span::before {
    transform: rotate(45deg);
  }
  span::after {
    transform: rotate(-45deg);
  }
}

.category-page__filter-toggle {
  @include size(56px, 56px);
  display: flex;
  align-items: center;
  background-color: $gray-40;
  border-radius: 8px;
  margin-top: 8px;
  justify-content: center;
  flex: none;
  transition: 0.2s;
}
.category-page__filter-toggle.collapsed {
  background-color: transparent;
}

.category-page__filter-toggle span {
  position: relative;
  width: 14px;
}

.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}
.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after,
.category-page__filter-toggle span {
  display: block;
  height: 2px;
  background-color: #BFC1C7;
  transition: 0.2s;
}

.category-page__filter-toggle span::before {
  transform: translateY(-6px);
  width: 20px;
  left: -3px;
}
.category-page__filter-toggle span::after {
  transform: translateY(6px);
  width: 8px;
  left: 3px;
}


.header__menu {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  width: 240px;
  position: absolute;
  top: 0;
  right: -240px;
  //transform: translateX(100%);
  background-color: #266FFF;
  overflow-y: auto;
  transition: 0.3s;

  .search-container_mobile {
    flex-grow: 0;
    padding: 16px 16px 24px;
  }
  .search-icon,
  .close-btn {
    display: none;
  }
  .inner-search {
    display: flex;
    grid-template-columns: 1fr 40px;
    align-items: center;
    width: 100%;
  }
  .search-input_mobile {
    height: 38px;
    border: 1px solid $border-color;
    border-right: none;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
    padding: 0 0 0 8px;
    transition: 0.2s;
    flex-grow: 1;
    @include font ($font, normal, 16px, 16px, $text-color);
    &:focus {
      border: 1px solid $border-color-active;
    }
    &::placeholder {
      @include font ($font, normal, 16px, 16px, $text-color-secondary)
    }
  }
  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(38px, 38px);
    overflow: hidden;
    padding: 4px;
    margin: 0;
    border-radius: 0 4px 4px 0;
    font-size:0;
    color: $main;
    &::before {
      content: '';
      @include size(17.49px, 17.49px);
      background: url('../img/search-white.svg') center no-repeat;
      background-size: contain;
      position: absolute;
    }
  }
  .socials {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    background-color: $gray-20;
    padding: 20px;
    .social-icon {
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.header__menu-wrap {
  flex-grow: 1;
  padding: 16px;
}
.header__menu_active .header__menu {
  right: 0;
  //transform: translateX(0);
}


/* END Mobile header */


/* START Mobile nav */

.nav__item_mobile {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
//.nav__line_mobile {
//  display: block;
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  height: 1px;
//  width: 100%;
//  background-color: rgba(0, 16, 61, 0);
//  transition: 0.3s;
//}
//.not_child .nav__line_mobile,
//.collapsed .nav__line_mobile {
//  background-color: $border-color;
//}
.nav__link_mobile {
  @include font ($font, 400, 16px, 32px, $white);
  padding-right: 32px;
  &:hover{
    color: white;
    opacity: 0.8;
  }
}
.menu_mobile .nav__link_mobile {
  @include font ($font, 600, 20px, 32px, $white);
  padding-bottom: 24px;
  padding-right: 0;
  &:hover{
    color: $white;
  }
}

.menu_direction{
  display: flex;
}
.menu_mobile{
  .menu_direction{
    flex-direction: column;
    align-items: flex-end}
}
//.active .nav__link_mobile {
//  color: $main !important;
//}
.nav__childrens_mobile {
  .nav__item_mobile {
    border-bottom: none;
    padding-left: 15px;
  }

  .nav__childrens_mobile {
    padding-left: 17px;
  }
  .nav__line_mobile {
    height: 100%;
    width: 1px;
    background-color: $main;
  }
  .not_child .nav__line_mobile,
  .collapsed .nav__line_mobile {
    background-color: $border-color;
  }
  .active .nav__line_mobile {
    background-color: $main !important;
  }
}

.nav__arrow_mobile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include size (46px, 46px);
}

.nav__arrow_mobile::before {
  content: '';
  display: block;
  @include size (0, 0);
  border: 5px solid transparent;
  border-top: 5px solid $main;
  transform: rotate(180deg);
}
.nav__arrow_mobile.collapsed::before {
  border-top: 5px solid #BFC1C7;
  transform: translateY(4px);
}

.nav__arrow_mobile.collapsed ~ .nav__link_mobile {
  color:  $text-color;
}
a.nav__arrow_mobile ~ a.nav__link_mobile {
  color: $main;
}







/* END Mobile nav */






.category-title {
  @include font($font, 600, 32px, 40px, $text-color);
  &:hover {
    color: $text-color;
  }
  &_side,
  &_side__is-link {
    @include font($font, 600, 24px, 32px, $text-color);
  }
  &_side__is-link {

  }
  &__is-link {
    @include font($font, 600, 32px, 40px, $text-color);
    display: flex;
    align-items: center;
    transition: .2s;
    h1, h2, h3, h4, h5, h6 {
      font-weight: 600 !important;
    }
    &:hover {
      color: $main;
    }
    /*&::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url("../img/link-arrow.svg") no-repeat center;
      background-size: contain;
      flex: none;
      margin-left: 16px;
    }*/
  }
}



/* SLIDER */


.banner-container .swiper-slide {
  display: flex;
  align-items: center;
}
.swiper-slide a {
  width: 100%;
  display: block;
}

.slider-banner-wraper {
  position: relative;
  width: 100%;
  height: auto;
  //padding-bottom: 28%;
}
.slider-banner-wraper.side {
  //padding-bottom: 56.25%;
}
.slider-banner {
  //position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.slider-banner__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.flexslider.control-nav {
  box-shadow: none
}
.flexslider .slides img {
  object-fit: contain;
}


.banner {
  display: flex !important;
  align-items: center;
  min-height: 144px;
  background-color: $gray-40;
  border-radius: 8px;
  padding: 32px;
}
.banner__img {
  width: 100%;
}
.slider-banner__square-img,
.banner__square-img {
  flex: none;
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 24px;
  object-fit: contain;
}
.slider-banner__title,
.banner__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
  line-height: calc(20px + 4 * ((100vw - 320px) / 1600));
  color: #2C2D2E;
  margin-bottom: 8px;
}
.slider-banner__caption,
.banner__caption {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: calc(14px + 6 * ((100vw - 320px) / 1600));
  line-height: calc(20px + 12 * ((100vw - 320px) / 1600));
  color: #2C2D2E;
}



.flexslider.control-nav {
  padding: 0  !important;
  border: none !important;
  box-shadow: none  !important;
}

.flex-direction-nav a:before,
.flex-direction-nav a.flex-next:before {
  content: url("../img/slider-arrow.svg") !important;
}
.flex-direction-nav a.flex-prev:before {
  transform: rotate(180deg);
}

.swiper-pagination-bullet-active {
  background-color: $main !important;
}


/* INSTAGRAM */




.insta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.insta__item {
  width: 20%;
  padding-right: 24px;
}


.insta-block {
  position: relative;
  height: 0px;
  overflow: hidden;
  padding-bottom: 100%;
  .insta-block__img {
    transition: .2s;
  }
  &:hover {
    .insta-block__img {
      filter: brightness(0.75);
    }
  }
}
.insta-block__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}
.insta-gradient {
  position: relative;
}

.insta-block__info {
  height: 0px;
  width: 100%;
  background: #fff;
  border: 1px solid #D5D4D4;
  border-bottom: none;
  z-index: 3;
  opacity: 0;
  transition: 0.3s;
}
.insta-block__info a {
  z-index: 4;
}
.insta-block:hover .insta-block__info {
  height: 60px;
  opacity: 1;
}
.insta-block__likes {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  opacity: 0;
  transition: 0.3s;
}
.insta-block:hover .insta-block__likes {
  opacity: 1;
}
.insta-block__bg {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  transition: 0.3s;
}
.insta-block:hover .insta-block__bg {
  background-color: rgba(0, 0, 0, 0.3);
}
.insta-block__prof-img-border {
  height: 40px;
  width: 40px;
  background: linear-gradient(45deg, #FEDA75, #FA7E1E, #D62976, #962FBF);
  border-radius: 50%;
  /*border: 2px solid #D62976;*/
}
.insta-block__prof-img {
  position: absolute;
  border-radius: 50%;
  box-sizing: content-box;
  border: 2px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 32px;
  width: 32px;
  object-fit: cover;
}
.insta-gradient__a {
  display: block;
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
  z-index: 20;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
}
.subscribe-btn {
  color: #2D9CDB;
}



/* YOUTUBE */


.youtube {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  overflow-x: auto;
}

.youtube__item {
  display: flex;
  flex-direction: column;
  min-width: 260px;
  .index-news-list__img {
    transition: .2s;
  }
  &:hover {
    .youtube__text-block {
      background-color: $gray-40;
    }
    .index-news-list__img {
      filter: brightness(0.75);
    }
  }
}

.youtube-block__btn {
  position: absolute;
  height: 65px;
  width: 65px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 3;
  transition: 0.3s;
}
.youtube-block__btn:hover {
  height: 69px;
  width: 69px;
}

.youtube__text-block {
  display: block;
  background-color: $gray-20;
  padding: 16px 24px 24px;
  color: #2C2D2E;
  flex-grow: 1;
  transition: .2s;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.youtube-block {
  position: relative;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.youtube__text {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}


/* PAGE */


.page__date {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $text-color-secondary;
  margin-bottom: 24px;
}
.page__lead {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: $text-color;
}
.page__main-img {
  margin-bottom: 32px;
}

.title-header {
  margin-bottom: 32px;
}

.sub-events__title {
  @include font($font, 600, 32px, 40px, $text-color);
}
.sub-event {
  margin: 32px 0;
}
.sub-event__title {
  @include font($font, 500, 24px, 32px, $text-color);
  margin-bottom: 8px;
}
.sub-event__date {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $text-color-secondary;
}
.event-card__body_block.side {
  background-color: $gray-20;
  padding: 24px 32px 32px;
  margin-top: 0;
}




/* PAGINATION */


.pagination {
  margin: 0;
}
.pagination-block {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  margin: 16px 0 32px;
  width: 100%;
}
.paginate-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 38px;
  border: 1px solid transparent;
  border-radius: 19px;
  padding: 7px 10px;
  margin: 0 4px;
  font-size: 14px;
  line-height: 20px;
  color: $text-color;
  text-align: center;
  text-decoration: none;

}
.pagination__arrow use {
  stroke: $border-color-active;
}
.pagination__prev-arrow {
  transform: rotate(180deg) translate(1px, 0);
}
.pagination__next-arrow {
  transform: translate(1px, 0);
}
.paginate-item.active {
  border-color: $border-color;
}
.plugin-paginate .paginate-item {
  border-color: #333;
  margin: 0 6px;
}
.paginate-item:hover,
.paginate-item:hover .pagination__arrow use{
  text-decoration: none;
  border-color: $border-color-active;
  color: $main;
  stroke: $border-color-active;
}
.paginate-item.disabled,
.disabled .pagination__arrow use,
.paginate-item.disabled:hover,
.paginate-item.disabled .pagination__arrow use
{
  border-color: transparent;
  color: $border-color-active;
  stroke: $border-color-active;
  cursor: unset;
}
.plugin-paginate .paginate-item.disabled {
  border-color: $gray-20;
}



/* LEADER */



.contact-leader {
  display: grid;
  grid-auto-columns: 200px 1fr;
  background-color: $gray-20;
  border: none;
  border-radius: 8px;
  min-height: 304px;
  overflow: hidden;
}

.contact-leader__img {
  object-fit: cover;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
}
.contact-leader__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  grid-column-start: 2;
  grid-column-end: 3;
}
.contact-leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 12px;
}
.contact-leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $text-color;
  margin-bottom: 16px;
}

.card-leader {
  padding: 32px;
  background-color: $gray-20;
}
.card-leader__head {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid $border-color;
}
.card-leader__img {
  flex: none;
  height: 128px;
  width: 128px;
  border-radius: 64px;
  object-fit: cover;
  margin-right: 32px;
}
.card-leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 8px;
}
.card-leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-secondary;
  margin-bottom: 0;
}
.card-leader__body {
  padding-top: 24px;
}
.card-leader__contact-item {
  display: flex;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 24px;
}
.card-leader__contact-item:last-child {
  margin-bottom: 0;
}
.card-leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}
.leader_search,
.leader {
  display: flex;
  padding: 32px;
  border: 1px solid $border-color;
  transition: 0.2s ease-out;
}
.leader_search {
  padding: 32px 0;
  border: none;
  border-bottom: 1px solid $border-color;
}

.leader.leader_lv-1 {
  border: 1px solid $main;
}

.leader_parent {
  background: #F3F3F5;
  position: relative;
}
.leader__arrow_parent {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  transition: 0.2s ease-out;
}
.leader__arrow_parent:hover {
  cursor: pointer;
}
.active > .leader__arrow_parent,
.active > .leader_parent > .leader__arrow_parent {
  transform: rotate(180deg);
}

.leader__img {
  flex: none;
  height: 128px;
  width: 128px;
  border-radius: 64px;
  object-fit: cover;
  margin-right: 32px;
}
.leader__head {
  border-bottom: 1px solid $border-color;
  padding-bottom: 24px;
}
.sub-units-wrap .leader__head {
  border-bottom: none;
  padding-bottom: 16px;
}
.sub-units-wrap .leader__body {
  padding-top: 0;
}
.leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-secondary;
  margin-bottom: 8px;
}
.leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 0;
}
.leader__content {
  width: 100%;
}
.leader__body {
  padding-top: 24px;
}
.leader__contact-item {
  display: flex;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 24px;
}
.leader__contact-item:last-child {
  margin-bottom: 0;
}
.leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.sub-units-wrap {
  height: 0px;
  padding-left: 64px;
  border-left: 2px dotted #D9D9DE;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  margin-top: -28px;
  padding-top: 28px;
}
.sub-units-wrap_lv-4 {
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  border-radius:  0 0 8px 8px;
}
.leader_lv-4 {
  padding: 32px;
  display: flex;
  border-bottom: 1px solid $border-color;
}
.leader_lv-4:last-child {
  border-bottom: none;
}
.sub-units-wrap.active {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
}
.active > .sub-units-wrap_lv-4 {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
  border: 1px solid $border-color;
  border-top: none;
}
.leader_lv-3 {
  position: relative;
}
.leader__main_lv-3 {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 32px;
  display: flex;
}
.active > .leader__main_lv-3 {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.leader_lv-3::before {
  content: '';
  display: block;
  position: absolute;
  border-top: 2px dotted #D9D9DE;
  height: 1px;
  width: 64px;
  left: -64px;
  top: 50%;
}
.leader_lv-3:last-child::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  left: -67px;
  height: 50%;
  width: 3px;
  top: calc(50% + 2px);
}

.leader-page__title {
  @include font($font, 600, 40px, 56px, $text-color);
  margin-bottom: 8px;
}
.leader-page__lead {
  @include font($font, normal, 32px, 48px, $text-color);
  margin-bottom: 24px;
}
.leader-page__content-label {
  @include font($font, normal, 16px, 24px, $text-color);
  margin-bottom: 4px;
  margin-top: 24px;
}
.leader-page__content-item {
  @include font($font, normal, 24px, 32px, $text-color);
  margin-bottom: 24px;
}
.leader-page__content-url {
  @include font($font, normal, 24px, 32px, $text-color);
  text-decoration: underline;
  margin-bottom: 24px;
  transition: .2s;
}
.leader-page__content-url:hover {
  color: $main;
  text-decoration: underline;
}


/* EVENTS */


.event-card_list {
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid $border-color;
}
.event-card_side {
  display: flex;
  padding: 12px 0;
  color: $text-color;
}
.event-card_side:hover {
  color: $text-color;
}
.category-title__button_side {
  @include font($font, 600, 16px, 24x, $text-color);
  display: flex;
  align-items: center;
  &:hover {
    color: $main;
  }
}

.category-title__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include font($font, 600, 16px, 24px, $text-color);
  padding: 11px 31px;
  border: 1px solid $border-color;
  transition: .2s;
  &:hover {
    color: $main;
    border-color: $border-color-active;
  }
}
.category-title__button_side,
.category-title__button {
  .category-title__button-svg {
    stroke: $main;
    margin-right: 12px;
  }
}
.category-title__is-link {
  .category-title__button-svg {
    stroke: $main;
    margin-left: 20px;
    transform: scale(1.25);
    margin-top: -2px;
  }
}
.category-title__is-link_side,
.category-small-title__is-link {
  @include font($font, 600, 24px, 32px, $text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s;
  h4, h3, h2, span, p {
    @include font($font, 600, 24px, 32px, $text-color);
    &:hover {
      color: $main;
    }
  }
  &:hover {
    color: $main;
  }
  .category-title__button-svg {
    stroke: $main;
    margin-left: 16px;
    transform: scale(1.2);
    margin-top: -4px;
  }
}

.event-card__date_list {
  @include font($font, normal, 12px, 16px, $text-color);
  color: $text-color-secondary;
  margin-bottom: 8px;
}
.event-card__date_side {
  @include font($font, normal, 12px, 16px, $text-color-secondary);
  margin-bottom: 8px;
}
.event-card__title_side {
  @include font($font, normal, calc(16px + 4 * ((100vw - 320px)/ 1600)), calc(24px + 8 * ((100vw - 320px)/ 1600)), $text-color);
  margin-bottom: 0;
}
.event-card__title_list {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: $text-color;
}
.event-card__img-container_list {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 54%;
}
.event-card__img_list {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-position: center;
  object-fit: cover;
}

.event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 58%;
}
.event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 4px;
}
.event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 16px;
}
.event-card__title_block {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.event-card__date_block {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $text-color-secondary;
  margin-bottom: 0;
}

.preview-event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.preview-event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 58%;
  &._hide-anons {
    display: none;
  }
}
.preview-event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.preview-event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
}
.preview-event-card__title_block,
.preview-event-card__title_list {
  @include font ($font, 500, 20px, 32px, $text-color);
  margin-bottom: 8px;
}
.preview-event-card__date_block {
  @include font ($font, normal, 14px, 16px, $text-color-secondary);
  margin-bottom: 0;
}
.preview-event-card_list {
  display: block;
  margin-bottom: 32px;
}

.preview-event-card__date_list {
  display: block;
  @include font ($font, normal, 14px, 16px, $text-color-secondary);
}
.top-news {
  margin-bottom: 16px;
}
.top-news .right-col .news-item:first-child {
  display: none;
}


.more-news.side_wraper {
  display: block;
  width: 100%;
  padding: 32px;
  background: $gray-20;
  border-radius: 8px;
}
.more-news__title {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 16px;
}
.more-news__item {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 16px;
}
.more-news__item:last-child {
  margin-bottom: 0;
}
.more-news__item.active,
.more-news__item:hover {
  text-decoration: underline;
}


/* DOCUMENTS */


.document_list {
  padding: calc(24px + 8 * ((100vw - 320px) / 1600)) 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $border-color;
}
.document__title_list {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: calc(16px + 8 * ((100vw - 320px) / 1600));
  line-height: calc(24px + 8 * ((100vw - 320px) / 1600));
  color: $text-color;
  margin-bottom: 16px;
}
.document_list.single:last-of-type,
.document_list.single:last-child,
.list-item:last-child * {
  border-bottom: none;
}
.document__art_list {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 0;
}
.document__info_block,
.document__info_list {
  display: flex;
  align-items: center;
}
.document__type_block,
.document__type_list {
  display: block;
  height: 16px;
  width: 40px;
  background-color: $main;
  border-radius: 2px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}
.document__size_block,
.document__date_block,
.document__size_list,
.document__date_list {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 0;
}
.document__size_block,
.document__size_list {
  margin-left: 8px;
}
.document__date_list {
  margin-left: 24px;
}
.document__date_block {
  margin-bottom: 20px;
}
.document__open_block,
.document__open_list {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $main;
  margin-bottom: 0;
}
.document_block {
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid $border-color;
  border-radius: 8px;
  transition: 0.2s ease-out;
}
.document_block:hover {
  border: 1px solid $border-color-active;
}
.event-card_block.side,
.document_block.side {
  height: unset;
  margin: 16px 0;
}
.document__title_block {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 20px;
  flex-grow: 1;
  height: 118px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.document__info_block {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  padding-top: 20px;
}
.service_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 4px solid $main;
  height: 100%;
  min-height: 260px;
  background-color: $gray-20;
  padding: 16px 20px 20px;
  transition: .2s;
}
.service_list:hover {
  background-color: $gray-40;
}
.service__title_list {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.service__description_list {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 8px;
}
.service__organisation_list {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 8px;
}
.service__img_list {
  display: block;
  height: 48px;
  width: 48px;
  flex: none;
}

.organisation {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: $gray-20;
  border-radius: 8px;
  height: 100%;
}
.organisation.side {
  height: fit-content;
}
.organisation__img {
  height: 128px;
  width: 128px;
  border-radius: 64px;
  overflow: hidden;
  object-position: center;
  object-fit: cover;
  margin-bottom: 12px;
}
.organisation__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 12px;
}
.organisation__description {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 12px;
}
.organisation__item {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 12px;
}
.organisation__url {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #005BD1;
  margin-bottom: 0;
}


/* FILTERS */

.data-chooser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: $gray-20;
}
.data-chooser::after {
  border: none;
  display: block;
  height: 24px;
  width: 24px;
  content: url("../img/small-arrow.svg");
}

.data-chooser__dropdown-menu {
  width: 100px;
  background-color: #fff;
  padding: 8px 0;
  border: none;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  .data-chooser__dropdown-item {
    width: 100%;
    display: block;
    white-space: unset !important;
    @include font($font, 400, 16px, 24px, $text-color);
    padding: 8px 16px;
    text-decoration: none;
    :hover,
    :active {
      background-color: $gray-20;
    }
  }
}

.rubric__header {
  display: flex;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
}
/*.rubric__header::after {
  display: block;
  height: 24px;
  width: 24px;
  content: url("../img/small-arrow.svg");
  margin: 16px;
  transition: 0.2s ease-out;
}*/
.rubric__header.active::after {
  transform: rotate(-180deg);
}
.rubric__container .rubric__container {
  margin-left: 16px;
}
.main-checkbox {
  display: inline-flex;
  align-items: center;
  background: $gray-20;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 8px 0;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background: $gray-40;
  }
  input {
    display: none;
  }
}
.main-checkbox.active {

}

.main-checkbox::before {
  content: '';
  display: block;
  flex: none;
  height: 16px;
  width: 16px;
  border: 2px solid #333;
  border-radius: 2px;
}
.main-checkbox.active::before {
  border: 2px solid $main;
  background: url(../img/checked.svg) no-repeat center $main;
  background-size: contain;
}
.main-checkbox__text {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 12px;
  color: $text-color;
}
.date-select {
  display: flex;
  @include font($font, normal, 16px, 24px, $text-color);
  &:hover {
    cursor: pointer;
  }
}
.date-select__icon {
  display: block;
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  margin: 0 8px;
}
.date-range_wrap {
  margin: 24px 0;
}
.date-range-input {
  border: none;
  color: #333;
  font-size: 14px;
  line-height: 24px;
  &:active {
    border: none;
    outline: none;
  }
}
.date-select__close {
  @include font($font, normal, 12px, 18px, $text-color-secondary);
}

/* FOOTER */


/* SMALL NAVIGATE */


.nav-counter {
  @include font($font, normal, 14px, 31px, #919399);
  padding-right: 8px;
}
.nav-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 31px;
  border: 1px solid $border-color-active;
  border-radius: 50%;
  margin-left: 8px;
}
.nav-arrow:hover {
  cursor: pointer;
}

.nav-arrow::before {
  content: url("../img/small-arrow.svg");
  height: 22px;
}
.nav-arrow.prev::before {
  transform: rotate(90deg);
}
.nav-arrow.next::before {
  transform: rotate(-90deg);
}
.nav-arrow.disable {
  opacity: 0.5;
  pointer-events: none;
}



/* USEFUL LINKS */



.u-links {
  /*display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;*/
  height: 100%;
}
.u-links__item_wrap {
  height: 100%;
}
.u-links__item {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 24px;
  transition: .2s;
}
.u-links__item.side {
  height: fit-content;
}
.u-links__item.bordered {
  border: 1px solid $border-color;
}
.u-links__item.bordered:hover {
  border: 1px solid  $border-color-active;
}

.u-links__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
}
.u-links__link {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
}
.u-links__img {
  max-width: 80px;
  max-height: 80px;
  opacity: 0.5;
  transition: .2s;
}
.u-links__item:hover .u-links__img {
  opacity: 1;
}



.footer {
  flex: 0 0 auto;
  padding: 16px;
  background-color: white;
}
.blue_bottom{
  width: 100%;
  height: 12px;
  background-color: $main;
}

.address__title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.address__address {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__link {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__link:hover {
  text-decoration-line: underline;
  color: $main;
}
.footer__subnav__item {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 0;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
}
.footer__subnav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__subnav__list__item {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  width: fit-content;
  margin-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
}

.footer__c-info {
  display: flex;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: $gray-20;
  margin: 0;
}
.footer__socials-title {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #BFC1C7;
  margin: 0;
}
.footer__teh-title {
  margin-bottom: 0;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
}
.footer__teh-subtitle {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
  margin-bottom: 0;
}

.up-button__button {
  position: absolute;
  top: 16px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  border: 1px solid $main;
  border-radius: 22px;
}
.up-button__button::before {
  content: url("../img/ArrowToTop.svg");
  height: 28px;
  width: 20px;
}


/* FORMS */


.form-container {
  max-width: 520px;
}

.main-link {
  display: flex;
  @include font ($font, 600, 16px, 24px, $text-color);
  padding: 8px 0;
  width: fit-content;
  &:hover {
    color: $main;
  }
  &::before {
    content: url("../img/Link.svg");
    display: block;
    @include size(40px, 40px);
    flex: none;
    margin: -6px 16px 0 0;
  }
}
.main-btn_outline {
  display: inline-block;
  padding: 11px 39px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  color: $main;
  border: 1px solid $main;
  border-radius: 4px;
  text-align: center;
  transition: .2s;
}
.main-btn_outline:hover {
  color: #fff;
  background-color: $main;
}

.modal-form-btn {
  @include font($font, 600, 16px, 24px, $text-color);
  display: block;
  border: 1px solid $border-color;
  padding: 32px;
  transition: .2s;
}
.modal-form-btn:hover {
  border-color: $border-color-active;
  color: $text-color;
}


.form__title_modal {
  @include font($font, 600, 32px, 40px, $text-color);
  margin-bottom: 8px;
}

.form__description_modal {
  @include font($font, normal, 18px, 24px, $text-color);
  margin-bottom: 8px;
}

.form__label {
  @include font ($font, 400, 14px, 24px, $text-color);
  margin-left: 12px;
}

.form-field_danger {
  @include font ($font, normal, 14px, 24px, unset);
}
.youtube {
  grid-gap: 16px;
}

.sitemap__ul {
  list-style: none;
  margin: 0;
  padding: 0;
  .sitemap__ul {
    padding: 0 0 0 16px;
    .sitemap__a {
      padding: 8px 15px;
      border-left: 1px solid $border-color;
    }
  }
}
.sitemap__li {
  transition: .2s;
  list-style: none;
  &:hover {
    cursor: pointer;
  }
}
.sitemap__a {
  display: block;
  position: relative;
  @include font($font, normal, 16px, 24px, $text-color);
  text-decoration: none;
  background-color: transparent;
  padding: 8px 15px;
  &:hover {
    color: $main;
    text-decoration: none;
  }
}



/* ---- WCAG ---- */

.wcag-panel {
  border: none;
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.2);
}


/* ---- TABLE ---- */

.page {
  table {
    @include font($font, normal, 16px, 24px, $text-color);
    border-collapse: collapse;
    border: 1px solid $border-color;
    thead {
      th {
        text-align: center;
        border: 1px solid $border-color;
      }
    }
    td {
      border: 1px solid $border-color;
    }
    a {
      display: block;
      text-decoration: none;
      color: $main;
      font-weight: 600;
      margin-bottom: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}



// Variables
$grey:   #f2f2f2;
$darker-grey: #c8c8c8;




@keyframes spin {
  0%   { transform: rotateZ(0); }
  100% { transform: rotateZ(360deg); }
}


//-------------------------------------------------------------------------

.first-screen {
  height: 400vh;
  padding: 0 128px;
  display: flex;
  justify-content: center;
}

.first-screen_content {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 32px;
  width: 1552px; // width: 1296px
  height: 100vh;
  max-width: 100%;
  padding: 0 0 20vh;

}








.scroll-downs {
  position: absolute;
  right: 0;
  bottom: 64px;
  left: 0;
  margin: auto;

  width :34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

















.header {
  flex: none;
}
.main-screen {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
.main-screen__sequences {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.main-screen__title{
  position: relative;
  @include font($font, 400, 96px, 112px, $white);
  z-index: 2;
}

.bg-blue{
  background: $main;
}
.bg-gray{
  background: #F3F3F5;
}
.bg-footer{
  background: #ECEDF0;
}


b{
  @include font($font, 700, 96px, 112px, $white);
}

.container_center{
  max-width: 1920px;
  padding: 0 128px;
  margin: 0 auto;
}
.container_center-content{
  max-width: 1138px;
  padding: 0 128px;
  margin: 0 auto;
}
.stamp_container{
  max-width: 1440px;
  padding: 0 128px;
  margin: 0 auto;
}
.horizontal_stamp{
  max-width: 1073px;
  width: 100%;
}
.concept{
  padding: 72px 0 96px 0;
}
.concept-title{
  @include font($font, 600, 64px, 72px, $text-color);
  padding-bottom: 24px;
  margin: 0;
}
.uniform-title{
  @include font($font, 600, 64px, 72px, $text-color);
  padding-bottom: 16px;
  margin: 0;
}
.shop-title{
  @include font($font, 600, 48px, 64px, $text-color);
  padding-bottom: 48px;
  margin: 0;
}

.concept-subtitle{
  @include font($font, 400, 24px, 64px, $text-color);
  margin-bottom: 0;
}
.concept-text{
  @include font($font, 400, 14px, 20px, $text-color);
  padding-left: 24px;
}
.scalability{
  padding-top: 144px;
  padding-bottom: 80px;
}
.scale_img{
  max-width: 32px;
  height: auto;
}
.circle{
  padding: 17px 20px 15px 20px;
  background: $main;
  border-radius: 50%;
}
#grid {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto auto auto;
  align-items: end;
  justify-items: center;
  grid-gap: 12px 64px;
  width: fit-content;
  max-width: 100%;
  padding-top: 40px;
}


.img_1_1{
  width: 128px;
  max-width: 100%;
  height: 160px;
}
.img_1_15{
  width: 96px;
  max-width: 100%;
  height: 120px;
}
.img_1_2{
  width: 64px;
  max-width: 100%;
  height: 80px;
}
.img_1_4{
  width: 32px;
  max-width: 100%;
  height: 40px;
}
.img_1_8{
  width: 16px;
  max-width: 100%;
  height: 20px;
}
svg.img_1_1,
svg.img_1_15,
svg.img_1_2,
svg.img_1_4,
svg.img_1_8 {
  align-self: start;
}
.phone_1{
  margin-top: -450px;
}
.phone_2{
  margin-top: 450px;
}

.uniform_diff_year{
  @include font($font, 400, 16px, 32px, $text-color-secondary);
  padding-top: 8px;
  margin-bottom: 0px;
}
.unifofm_text{
  @include font($font, 400, 14px, 24px, $text-color);
}
.unifofm_secondary_text{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  margin-bottom: 0;
}
.uniform_svg{
  max-width: 512px;
  width: 100%;
  height: 100%;
}
.text_rect{
  @include font($font, 400, 14px, 24px, $text-color);
  //border: 1px solid $gray-40;
  padding: 16px 24px;
  background: $gray-40;
  margin-top: 40px;
}
.text_rect_big{
  @include font($font, 400, 16px, 32px, $text-color);
  //border: 1px solid $gray-40;
  padding: 16px 24px;
  background: $gray-40;
}
.uniform_gerb_block:first-child{
  padding-right: 24px;
}
.uniform_gerbs{
  padding-right: 200px;
  margin: 48px 0;
}
.scaleblock_padding{
  padding: 48px 0;
}
.uniform{
  margin-bottom: 96px;
}
.continuity-subtitle{
  max-width: 760px;
  @include font($font, 400, 24px, 40px, $text-color);
  margin-bottom: 0;

}
#grid_table{
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-template-columns: repeat(4, 1fr);
  align-items: end;
  justify-items: center;
  grid-gap: 48px 128px;
  width: 100%;
  max-width: 100%;
  margin-top: 48px;
}
.gerb_table_item{
  width: 100%;
  height: 100%
}
.gerb_table_text{
  @include font($font, 400, 12px, 16px, $text-color);
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;
}
.gerb_table_png{
  max-width: calc(50% - 8px);
  width: 100%;
  height: auto;
  margin-right: 16px;
}
.gerb_table_svg{
  max-width:  calc(50% - 8px);
  width: 100%;
  height: auto;
}
.download_block{
  padding-top: 144px;
}
.download_text{
  @include font($font, 400, 16px, 32px, $text-color);
  padding-top: 16px;
}
.download_button{
  background: #266FFF;
  position: relative;
  border-radius: 50%;
  width: 192px;
  height: 192px;
  display: block;
  text-decoration: none;
  box-shadow: 0px 0px 40px #266FFF;

  &:hover{
    animation-name: shadow_puls;
    animation-iteration-count:infinite;
    animation-duration: 2s;
    .border_blue{
      width: 384px;
      height: 384px;
    }
    .border_gray{
      width: 416px;
      height: 416px;
    }
  }
}
.download_button_two{
  background: #266FFF;
  position: relative;
  border-radius: 50%;
  width: 192px;
  height: 192px;
  display: block;
  text-decoration: none;
  box-shadow: 0px 0px 96px #266FFF;

}

.download_arrow{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 6px #fff) drop-shadow(0px 0px 16px #fff);
}
.gerb_frame{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}
.border_gray,
.border_blue{
  width: 191px;
  height: 191px;
  position: absolute;
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s cubic-bezier(0.73, 0.01, 0.12, 0.96);
}
.border_blue{
  border: 1px solid #266FFF;
}
.border_gray{
  border: 1px solid rgba(0, 16, 61, 0.12);
}
@keyframes shadow_puls {
  0% {
    box-shadow: 0px 0px 40px #266FFF;
  }
  60% {
    box-shadow: 0px 0px 112px #266FFF;
  }

  100% {
    box-shadow: 0px 0px 40px #266FFF;
  }
}
.concept_img{
  width: 100%;
  height: 100%;
  max-width: 112px;
  max-height: 112px;
}
.footer_text{
  @include font($font, 400, 16px, 24px, $main);
  text-align: center;
  display: flex;
  align-items: flex-end;
  text-align: start;
}
.purpose_gerb_text{
  @include font($font, 400, 24px, 40px, $text-color);
  margin: 0;
}
.purpose_gerb{
  padding: 48px 0 64px 0;
}
.example_block{
  padding-top: 120px;
}
.example_img{
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;

}
.example_text{
  @include font($font, 400, 16px, 24px, $text-color);
  margin: 0;
  width: 100%;
  max-width: 400px;
}
#example_grid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  justify-items: center;
  grid-gap: 48px 48px;
  width: 100%;
  max-width: 100%;
  margin-top: 48px;
}
.example_table{
  width: 100%;
  height: 100%;
}
.subtitle_download{
  @include font($font, 400, 20px, 32px, $text-color);
  max-width: 848px;
  margin-bottom: 48px;
}
.subtitle_crowd{
  @include font($font, 400, 20px, 32px, $text-color);
  max-width: 848px;
  margin-bottom: 56px;
}
.back_link_arrow{
  width: 24px;
  height: 24px;
}
.back_link_text{
  @include font($font, 400, 14px, 24px, $main);
  margin: 0;
  &:hover{
    text-decoration: underline;
  }
}
.oblast_title{
  @include font($font, 600, 32px, 48px, $main);
  margin-bottom: 4px;
}
.oblast_block{
  margin-bottom: 66px;
}
.oblast_block:hover .gerb_number{
  color: $main;
}
.oblast_block:hover .project_status{
  color: $main;
}
.oblast_block:hover .line_divide{
  color: $main;
  background: $main;
}
.oblast_block:hover .solid_gerb use{
  fill: $main;
}

.solid_gerb{
  width: 24px;
  height: 30px;
  margin-right: 8px;
}
.solid_gerb_big{
  width: 32px;
  height: 40px;
  margin-right: 8px;
}
.gerb_number{
  @include font($font, 400, 16px, 28px, $text-color-secondary);
  margin-right: 12px;
}
.gerb_number_big{
  @include font($font, 400, 20px, 36px, $text-color-secondary);
  margin-right: 20px;
}
.line_divide{
  width: 1px;
  height: 100%;
  background: $text-color-secondary;
  margin-right: 12px;
}
.project_status{
  @include font($font, 400, 16px, 28px, $text-color-secondary);
}
.project_status_big{
  @include font($font, 400, 20px, 36px, $text-color-secondary);
}

//.project_offer{
//  padding-bottom: 64px;
//}
.offer_title{
  @include font($font, 600, 32px, 48px, $main);
}
.offer_text{
  @include font($font, 400, 16px, 24px, $text-color);
  max-width: 700px;
  margin-top: 4px;
  margin-bottom: 24px;
}
//.agreement{
//  margin-top: 64px;
//}
.agreement_text{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  max-width: 850px;
}
.agreement_link{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  text-decoration: underline;
}
.text_gap{
  margin-bottom: 16px;
}
.download_img{
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.shop_img{
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
.marginy-24{
  margin: 24px 0;
}
.download_subtitle{
  @include font($font, 600, 16px, 24px, $text-color);
  margin: 0;
}
.download_designer{
  @include font($font, 400, 16px, 24px, $text-color);
}
.download_page_conception{
  margin-bottom: 24px;
}
.download_page_designer{
  margin-bottom: 48px;
}
.region_name{
  margin-bottom: 8px;
  @include font($font, 600, 16px, 24px, $text-color);

}
.download_gerb{
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 100px;
  margin-right: 24px;
  flex: none;
}
.file_gerb{
  @include font($font, 500, 14px, 24px, $white);
  background: #BFC1C7;
  padding: 0 8px;
  margin-right: 8px;
  &:hover{
    color: $white;
    background: $main;
  }
}
.grid_padding{
  padding-bottom: 64px;
}
.author_text{
  @include font($font, 400, 20px, 32px, $text-color);
  padding: 16px 48px 24px 48px;
  border: 1px solid $main;
}
.author_img{
  width: 100%;
  height: 100%;
  max-width: 96px;
  max-height: 96px;
}
.author_img_single{
  width: 100%;
  height: 100%;
  max-width: 96px;
  max-height: 96px;
}
.author_info{
  padding: 8px 24px;
  background: #F3F3F5;

}
.author_name{
  @include font($font, 600, 20px, 32px, $text-color);
}
.author_position{
  @include font($font, 400, 16px, 24px, $text-color);
}
.author_region{
  @include font($font, 400, 16px, 24px, $text-color-secondary);
}
.author_frame{
  margin: -16px 0 48px 16px;
}
.author_plugin{
  width: fit-content;
  padding-right:16px;
  padding-bottom:16px ;
  float: left;
}
.doc_download{
  background: #F3F3F5;
  padding: 8px 9px;
  border-radius: 50%;
  width: 32px;
  box-sizing: border-box;
  height: 32px;
  overflow: unset!important;
  margin-right: 12px;
}
.document{
  display: flex;
  margin-top: 24px;
  margin-bottom: 56px;
}
.document_submit{
  display: flex;
  margin-bottom: 32px;
}
.doc_download_text{
  @include font($font, 400, 16px, 32px, $main);
}
.footer_img{
  width: 100%;
  height: 100%;
  max-width: 24px;
  max-height: 30px;
  margin-right: 8px;
}
.header_text{
  @include font($font, 500, 16px, 28px, $white);
}
.pb_24{
  padding-bottom: 24px;
}
.pb_32{
  padding-bottom: 32px;
}
.header_desktop{
  .burger__span_close{
    display: none;
  }
}
.reward_subtitle{
  @include font($font, 400, 16px, 32px, #919399);
  margin-top: 16px;
  margin-bottom: 32px;
}
.input_file{
  opacity: 0;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.4px;
  height: 0.4px;
}

.file_name{
  @include font($font, 400, 16px, 24px, $main);
  display: flex;
  margin-top: 12px;
  &::before {
    content: url("../img/file_icon.svg");
    display: flex;
    @include size(16px, autopx);
    flex: none;
    margin-right: 8px;
  }

}
.no_file{
  @include font($font, 400, 16px, 24px, #919399);
  margin-top: 12px;
}
.header_link{
  height: fit-content;
  display:flex;
  align-items: start;
}

.top_btn{
  @include font($font, 400, 14px, 24px, $main);
  text-align: center;
  margin: 144px 0 24px 0;
  &:hover{
    cursor: pointer;
    color: $main;
  }
}
.rect_up{
  margin-left: 5px;
}

//  ВСПЛЫВАЮЩАЯ ФОРМА
.modal-content{
  //padding: 40px 0px 16px 16px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.modal_form_content{
  padding: 40px 32px 16px 32px;
}
.form_title{
  @include font($font, 400, 20px, 24px, $text-color);
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.form-content__title {
  @include font($font, 400, 14px, 16px, $text-color-secondary);
  margin-bottom: 8px;
  margin-top: 24px;
}
.form-content__text {
  @include font($font, 600, 14px, 16px, $text-color);
  margin-bottom: 8px;
}
.form-content__checklist-item {
  display: flex;
  @include font($font, 400, 14px, 20px, $text-color);
  margin-bottom: 8px;
  &::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.59 13.58L2.42 9.41L1 10.82L6.59 16.41L18.59 4.41L17.18 3L6.59 13.58Z' fill='%23266FFF'/%3E%3C/svg%3E%0A");
    display: flex;
    padding-right: 4px;
  }
}
.form-content__link-button {
  display: block;
  width: 100%;
  padding: 12px 16px;
  @include font($font, 600, 16px, 24px, $white);
  background-color: $main;
  margin-bottom: 8px;
  text-align: center;
  border-radius: 4px;
  margin-top: 16px;
  &:hover {
    color: $white;
  }
}
.form-content__license {
  @include font($font, 400, 12px, 16px, $text-color-secondary);
  margin-bottom: 0;
  text-align: center;
}
.form-content__license-link {
  text-decoration: underline;
  @include font($font, 400, 12px, 16px, $text-color-secondary);
}
.select_frame{
  padding: 12px 16px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 4px;
  position: relative;
  background: url("../img/select.svg") no-repeat #ffffff;
  background-position-x: calc(100% - 20px);
  background-position-y:center;
  margin-bottom: 24px;
  @include font($font, 400, 16px, 24px, $text-color);
  width: 100%;
  text-align: start;
}
select:focus-visible{
  border: 1px solid rgba(0, 16, 61, 0.12);
  outline: none;
}
select::-ms-expand {
  display: none;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form_subtitle{
  @include font($font, 400, 14px, 24px, $text-color);
  padding-left: 8px;
}
.form_img_wrap{

}
.form_img{
  object-fit: cover;
}
.license_link {
  @include font($font, 400, 16px, 24px, $main);
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.close_img{
  display: flex;
  justify-content: flex-end;
  width: 14px;
  height: 14px;
  flex: none;
}
.btn-close{
  width: 32px;
  height: 32px;
  padding: 9px;
}
.btn-close_dt{
  width: 32px;
  height: 32px;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border-radius: 50%;
  margin: 8px;
}
.license_price{
  @include font($font, 400, 32px, 40px, $text-color);
}
.license_text{
  @include font($font, 400, 16px, 24px, $text-color);
}
.license_price_block {
  background: #F9F9FA;
  border-radius: 8px;
  max-width: 336px;
  height: fit-content;
  padding: 12px 24px;
  margin-bottom: 16px;
}
.terms_of_use{
  @include font($font, 400, 14px, 24px, $main);
  display: flex;
  margin-bottom: 32px;
  &::before {
    content: url("../img/file_icon.svg");
    display: flex;
    @include size(16px, autopx);
    flex: none;
    margin-right: 8px;
  }
}
.dropdown-item.active, .dropdown-item:active{
  background-color: $main;
}
.dropdown-menu{
  width: 100%;
  max-width: 336px;
  @include font($font, 400, 16px, 24px, $text-color);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active, .btn-secondary.active, .show >
.btn-secondary.dropdown-toggle{
  color: #000;
  background-color: white;
  border: 1px solid rgba(0, 16, 61, 0.12);
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus{
  background-color: white;
  box-shadow: none;
}

.btn-secondary:hover{
  background-color: white;
  border: 1px solid rgba(0, 16, 61, 0.12);
}
.btn-secondary:active:focus{
  background-color: white;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: none;
}
.dropdown-toggle::after{
  display: none;
}
.souvenir_title{
  @include font($font, 600, 16px, 24px, $text-color);
}
.souvenir_description{
  @include font($font, 400, 16px, 24px, $text-color-secondary);
  word-break: break-word;
}
.souvenir_price{
  @include font($font, 400, 16px, 24px, $main);
  &::after{
    content: '₽';
    position: absolute;
    @include font($font, 400, 16px, 24px, $main);
    margin-left: 4px;
  }
}
.souvenir_img{
  width: 100%;
  height: auto;
  //max-width: 288px;
}
.souvenir-title{
  @include font($font, 600, 32px, 40px, $text-color);
}
.souvenir_price_detail{
  @include font($font, 500, 32px, 48px, $main);
  margin: 16px 0;
  &::after {
    content: '₽';
    position: absolute;
    @include font($font, 500, 32px, 48px, $main);
    margin-left: 4px;
  }
}
.shop-btn{
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #FFF;
  background-color: $main;
  border-radius: 8px;
  text-align: center;
  border: none;
  width: fit-content;
  white-space: nowrap;
  transition: 0.3s;
  &:hover{
    color: white;
    box-shadow: 0px 4px 12px rgba(29, 83, 191, 0.25);
  }
}
.pic_plugin{
  margin-top: 16px;
}
.shop-btn_wrap{
  padding-bottom: 16px;
}
.souvenir-title_form{
  @include font($font, 600, 16px, 24px, $text-color);
  margin-bottom: 0;
}
.souvenir_description_form{
  @include font($font, 400, 16px, 24px, $text-color-secondary);
}
.price_form{
  @include font($font, 400, 16px, 24px, $main);
  margin-bottom: 24px;
  &::after{
    content: '₽';
    position: absolute;
    @include font($font, 400, 16px, 24px, $main);
    margin-left: 4px;
  }
}
.souvenir_img_form{
  width: 100%;
  height: auto;
  max-width: 112px;
  margin-bottom: 16px;
}
.shop_modal-content{
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 32px;
  background: white;
  border-radius: 8px;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
}
.order_title{
  @include font($font, 400, 20px, 24px, $text-color);
  margin-bottom: 24px;
}
.form-control:focus{
  border: 1px solid rgba(0, 16, 61, 0.12);
}
.close_order{
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 9px;
  top: 8px;
  right: 9px;
}
.order_text{
  @include font($font, 400, 14px, 24px, $text-color);
}
.payment_btn{
  display: inline-block;
  width: 100%;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
  background-color: #266FFF;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-top: 64px;
  transition: 0.3s;
  &:hover{
    color: white;
    box-shadow: 0px 4px 12px rgba(29, 83, 191, 0.25);
  }
}
.subtitle_min{
  @include font($font, 400, 16px, 24px, $text-color);
  margin-bottom: 32px;
  max-width: 618px;
}
.create_lable{
  @include font($font, 400, 16px, 24px, $text-color);
}
.custom-radio-color-label{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.custom-radio-color:checked + .custom-radio-color-label::after{
  content: url("../img/checked.svg");
  //display: flex;
  //align-items: center;
  //height: 100%;
  //padding-top: 2px;
  //margin-left: 6px;
}
.custom-radio-color{
  display: none;
}

.select_frame_wizard {
  padding: 12px 16px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 4px;
  position: relative;
  background: url("../img/select.svg") no-repeat #ffffff;
  background-position-x: calc(100% - 20px);
  background-position-y: center;
  margin-bottom: 24px;
  @include font($font, 400, 16px, 24px, $text-color);
  width: 100%;
  max-width: 336px;
  text-align: start;
}

#id_step1-category,
#id_step1-municipality{
  padding: 12px 16px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 4px;
  position: relative;
  background: url("../img/select.svg") no-repeat #ffffff;
  background-position-x: calc(100% - 20px);
  background-position-y:center;
  margin-bottom: 24px;
  @include font($font, 400, 16px, 24px, $text-color);
  width: 100%;
  text-align: start;
}
.form-control{
  option{
    padding: 12px 16px;
  }
}
.wizard_help_text{
  @include font($font, 400, 14px, 24px, $text-color-secondary);
  padding-top: 8px;
  max-width: 336px;
}
//.checkbox_label{
//  @include font($font, 400, 16px, 24px, $text-color);
//  display: inline-flex;
//  &::before{
//    content: '';
//    display: block;
//    flex: none;
//    height: 18px;
//    width: 18px;
//    border: 1px solid $main;
//    border-radius: 2px;
//    background: $main;
//  }
//}
//.checkbox_label.checked{
//  &::before{
//    background: url(../img/checked.svg) no-repeat center $main;
//  }
//}
.business_card_mockup{
  width: 100%;
  height: auto;
  max-width: 288px;
  margin-top: 16px;
}
.create_title{
  @include font($font, 600, 24px, 32px, $main);
  margin-top: -48px;
  margin-left: 24px;
}
.soon{
  @include font($font, 400, 14px, 20px, $text-color-secondary);

}
.soon_title {
  @include font($font, 600, 24px, 32px, $text-color-secondary);
  margin-bottom: 32px;
}
.mt-64{
  margin-top: 64px;
}
.step3_subtitle{
  margin-top: 72px;
  @include font($font, 600, 24px, 32px, $text-color);
  margin-bottom: 8px;
}
.step3_text{
  @include font($font, 400, 16px, 28px, $text-color);
}
.create_again{
  @include font($font, 400, 16px, 24px, $main);
  text-decoration: none;
  white-space: nowrap;
  &:hover{
    color: $main;
  }
}

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
}
.custom-checkbox+label::before {
  content: '';
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid $text-color-secondary;
  border-radius: 4px;
  margin-right: 12px;
  flex: none;
}
.custom-checkbox:checked+label::before {
  content: '';
  overflow: hidden;
  background: url("../img/checked.svg") $main no-repeat center;
  border-color: $main;
}
.color_wrap{
  padding-left: 2px
}



//             паспорт

.accordion-button{
  @include font($font, 600, 20px, 24px, $main);
  background: white;
  border-radius: 10px;
  padding: 16px 24px;
}
.accordion{
  border: 1px solid $main;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}
.accordion-item{
  border-radius: 10px;
}
.accordion-button{
  background: white;
}
.accordion-button:not(.collapsed){
  color: $main;
  background: white;
  box-shadow: none;
}
.accordion-button:focus{
  box-shadow: none;
  border: none;
}
.accordion-flush .accordion-item{
  border-right: 10px;
}
.accordion-flush .accordion-item .accordion-button{
  border-radius: 10px;
}
.accordion-body{
  padding: 12px 24px 4px 24px;
}
.accordion-body_item{
  @include font($font, 400, 20px, 24px, $text-color);
  padding-bottom: 20px;
  b {
    @include font($font, 600, 20px, 24px, $text-color);
  }
}
.accordion-button::after {
  flex-shrink: 0;
  width: 12px;
  height: 8px;
  margin-left: auto;
  content: "";
  background-image: url("../img/v.svg");
  //background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-size: 12px;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../img/v.svg");
  //background-image: url(data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 16 16' fill= '%230c63e4' %3e%3cpath fill-rule= 'evenodd' d= 'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e);
  transform: rotate(-180deg);
}
.main_subtitle{
  @include font($font, 400, 24px, 40px, $text-color-secondary);
}
.stamp {
  padding-top: 32px;
  overflow: hidden;
}
.stamp_img{
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 480px;
  transform: rotate(180deg);
}
.main_text{
  @include font($font, 400, 32px, 48px, $text-color);
  padding-bottom: 12px;
}
.pt-64{
  padding-top: 64px;
}
.accordion-header{
  margin-top: 0;
}
.logo_img{
  width: 100%;
  height: 100%;
  max-width: 52px;
  margin-right: 16px;
}
.logo_text{
  @include font($font, 400, 20px, 24px, white);
}
.logo_text-bold{
  @include font($font, 700, 24px, 24px, white);
}
.logo_wrap{
  display: flex;
  align-items: center;
}
.footer_subtitle{
  @include font($font, 400, 14px, 16px, $text-color-secondary);
  margin: 0;
  text-align: center;
  padding-right: 8px;
}
.indicator{
  width: 100%;
  height: 100%;
  max-width: 127px;
}

.pasport_video{
  width: 100%;
  max-width: 960px;
  position: absolute;
  z-index: -1;
  //left: 609px;
  top: -56px;
  right: 0;
}
.back_img{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  //object-fit: cover;
}

//.block_back{
//  background-image: url("../img/back.svg");
//  background-size: contain;
//  background-repeat: no-repeat;
//  padding: 192px 112px 261px 112px;
//}
.white_title{
  @include font($font, 600, 48px, 64px, white);
  margin-bottom: 12px;
}
.main_text_white{
  @include font($font, 400, 32px, 48px, $white);
  padding-bottom: 12px;
}
.main_subtitle{
  @include font($font, 400, 24px, 40px, rgba(255, 255, 255, 0.5));
}
.subtitle{
  @include font($font, 400, 24px, 40px, $text-color-secondary);
}
.middle_block{
  max-width: 1362px;
  padding: 0 128px;
  margin: 0 auto;
  position: relative;
  margin-top: -160px;
}
.text_wrap{
  position: relative;
  z-index: 2;
  padding: 192px 112px 380px 112px;
  //padding: 48px 32px 48px;
}

.uk-margin-auto-vertical{
  margin-top: auto!important;
  margin-bottom: auto!important;
  z-index: 2;
  position: relative;

}
.passport_screen{
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 734px;
  margin-top: -340px;
  z-index: 2;
}
.main-screen__canvas{
  width: 920px;
  height: auto;
}
